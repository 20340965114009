import Network from '@/services/network'

export default function handleTemplate() {
    const network = new Network;

    const storeTemplate = (data, templateableId) => {
        return network.api('post', `/hospital/template-log/save-as-template/${templateableId}`, data);
    }
    const updateTemplateStatus = (data, templateableId) => {
        return network.api('put', `/hospital/template-log/update-template-status/${templateableId}`, data);
    }
    const removeTemplate = (query, templateableId) => {
        return network.api('delete', `/hospital/template-log/remove-template/${templateableId}` + query);
    }
    const fetchTemplates = (query) => {
        return network.api('get', `/hospital/template-log/template-list` + query);
    }

    return {
        storeTemplate,
        updateTemplateStatus,
        fetchTemplates,
        removeTemplate
    }
}