import writePrescription from '@/services/utils/pdf/writePrescription';
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';

const writePrescriptionHelper = () => {
    const { exportToPDF } = writePrescription();
    const { fetchBarcode } = handleBarcode();
    const store = useStore();
    const { formatDate } = useDate();
    const userName = store.state.user.user.name;
    var qrcode = '';

    const generatePdf = (company, notes, rxList, service, isHideHeader, isHideFooter) => {
        let qrcodeData = JSON.stringify({
            ref_no: service.sale_master.bill_number,
            ref_date: service.doctor_order.prescription_date ? service.doctor_order.prescription_date : formatDate(new Date, true),
            created_by: userName,
            created_date_time: formatDate(new Date())
        })

        var barCodeQuery = `?code=''&qrcode=${qrcodeData}`;

        Promise.all([
            fetchBarcode(barCodeQuery).then( (res) => {
                qrcode = res.qrcode;
            })
        ]).then(() => {
            exportToPDF(company, notes, rxList, service, qrcode, userName, isHideHeader, isHideFooter)
        }).catch((e) => {
            console.log(e);
        })
    }

    return{
        generatePdf
    }
}

export default writePrescriptionHelper;